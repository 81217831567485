@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}

html {
  scroll-behavior: smooth;
}